export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
    color: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
    driver: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
    number: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
    guest_car_model_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
    staff_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' }      
    ],
};
