import request from '@/utils/request'

export function index(params) {
    return request({
        url: 'kpp/late-to-works',
        method: 'get',
        params: params
    })
}


export function show(id) {
    return request({
        url: `/kpp/late-to-works/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: `kpp/late-to-works`,
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `kpp/late-to-works/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `kpp/late-to-works/${id}`,
        method: 'delete',
    })
}

export function getVisitors(id) {
    return request({
        url: `/visitors/guest_visitors/${id}`,
        method: 'get'
    })
}
export function reasons(params) {
  return request({
      url: '/kpp/late-to-work/reasons',
      method: 'get',
      params
  })
}
