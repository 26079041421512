export const rules = {
    device_name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    device_type_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    security_location_id: [
      { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    status_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    attendance_type: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    port: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    ip_address: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
