import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/attendances_department',
      method: 'get',
      params: params
  })
}

export function getDepartmentStaffList(params) {
  return request({
      url: `/attendances_department/departments/staffs`,
      method: 'get',
      params: params
  })
}
export function getDepartmentStaffListComeIn(params) {
  return request({
      url: `/attendances_department/departments/staffs/come`,
      method: 'get',
      params: params
  })
}
export function getDepartmentStaffListExit(params) {
  return request({
      url: `/attendances_department/departments/staffs/exit`,
      method: 'get',
      params: params
  })
}

export function getDepartmentStaffListIzn(params) {
  return request({
      url: `/attendances_department/departments/staffs/izn`,
      method: 'get',
      params: params         
  })
}
export function getDepartmentStaffListNotComeIn(params) {
  return request({
      url: `/attendances_department/departments/staffs/notizn`,
      method: 'get',
      params: params   
  })
}
