import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  performance: {
    show: true,
    title: 'Ishlash turi',
    sortable: true,
    column: 'performance'
  },

 
  reason: {
    show: true,
    title: 'Sabab',
    sortable: true,
    column: 'reason'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  date: {
    show: true,
    title: 'Vaqti',
    sortable: true,
    column: 'updated_at'
  },
  count: {
    show: true,
    title: 'Soni',
    sortable: true,
    column: 'updated_at'
  },
  header: {
    show: true,
    title: 'Tafsilotlar',
    sortable: true,
    column: 'header'
  },
  status: {
    show: true,
    title: 'Status',
    sortable: true,
    column: 'status'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
