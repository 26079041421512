export const filter = {
    id: null,
    name: '',
    last_name: '',
    middle_name: '',   
    date_of_birth: null,
    gender_id: null,
    status_id: null,
    phone_number: null,    
    email: '',    
    allow_to_be_user: false,
    created_at: '',
    updated_at: '',
};
