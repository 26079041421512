export const getters = {
    list: state => state.list,
    staffCount: state => state.staffCount,
    staff_list: state=>state.staff_list,
    excel_staff_list: state=>state.excel_staff_list,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    paginationAttendance: state => state.paginationAttendance,
    sort: state => state.sort
};
