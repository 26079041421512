import {getZktecoUser,createZktecoUser,deleteZktecoUser } from "@/api/zkteco/zkteco";

export const actions = {    

    getZktecoUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            getZktecoUser(id)
                .then(res => {
                    commit("SET_ZKTECO_USER", res.data.result.data.zkteco_user);
                    commit("SET_ZKTECO_DEVICES", res.data.result.data.device_ids);                 
                    commit("SET_ZKTECO_LOCATION", res.data.result.data.location_ids);                 
                    resolve(res.data.result.data.zkteco_user);                   
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    createZktecoUser({ commit }, data) {
        return new Promise((resolve, reject) => {
            createZktecoUser(data)
                .then(res => {                                    
                    resolve(res);                   
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    deleteZktecoUser({ commit }, zkteco_user_id) {
        return new Promise((resolve, reject) => {
            deleteZktecoUser(zkteco_user_id)
                .then(res => {                                    
                    resolve(res);                   
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


};
