import { index,attendance,getDepartmentStaffListExit,getDepartmentStaffList,getDepartmentStaffListComeIn, getDepartmentStaffListNotComeIn,getDepartmentStaffListIzn} from "@/api/attendance/attendanceDepartment";

export const actions = {    
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {                
                commit("SET_LIST", res.data.result.data.attendances);
                commit("SET_STAFF_INFO", res.data.result.data.staff_list_info);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    staffAttendanceList({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            attendance(params).then(res => {                
                commit("SET_STAFF_INFO", res.data.result.data.staff_list_info);

                // commit("UPDATE_PAGINATION_ATTENDANCE", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getDepartmentStaffListComeIn({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        getDepartmentStaffListComeIn(params)
              .then(res => {
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    getDepartmentStaffListExit({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        getDepartmentStaffListExit(params)
              .then(res => {
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    
    getDepartmentStaffList({ commit }, params={}) {
      return new Promise((resolve, reject) => {
          getDepartmentStaffList(params)
              .then(res => {
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },

    getDepartmentStaffListIzn({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        getDepartmentStaffListIzn(params)
              .then(res => {
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    getDepartmentStaffListNotComeIn({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        getDepartmentStaffListNotComeIn(params)
              .then(res => {
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    updateSort({ commit }, sort) {
      commit("SET_SORT", sort)
  },

  updateFilter({ commit }, filter) {
      commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
  },

  updateColumn({ commit }, obj) {
      commit('UPDATE_COLUMN', obj)
  },
  updatePagination({ commit }, pagination) {
      commit('UPDATE_PAGINATION', pagination)
  },
  refreshData({ commit }) {
      return new Promise((resolve, reject) => {
          commit("REFRESH");
          resolve()
      })
  },
  empty({ commit }) {
    return new Promise((resolve, reject) => {
        commit("EMPTY_MODEL");
        resolve()
    })
},
};
