export const filter = {
  id : '',  
  device_name: '',
  serial_number:'', 
  device_type_id:'',
  security_location_id:'',
  status_id: null,
  is_active: false,
  created_at: '',
  updated_at: ''  
};
