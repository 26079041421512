import request from '@/utils/request'

export function index(params) {
  return request({
      url: 'hr/permissions',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: 'hr/permission/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `hr/permissions/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: 'hr/permissions',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `hr/permissions/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `hr/permissions/${id}`,
      method: 'delete',
  })
}
export function create() {
    return request({
        url: 'hr/role/create',
        method: 'get',
    })
  }
